* {
	box-sizing: border-box;
}

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	box-sizing: border-box;
	overflow: hidden;
	height: 100%;
	margin: 0;
	color: var(--default-text-color);
	-ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
	scrollbar-width: none; /* Firefox */
	::-webkit-scrollbar {
		// display: none;
		transition: 0.4s;
		width: 10px;
		height: 10px;
		opacity: 0;

		:hover {
			opacity: 1;
		}
	}

	::-webkit-scrollbar-track {
		// border-left: 1px solid #e5e5e5;
		border-radius: 5px;
		transition: 0.2s;
	}
	::-webkit-scrollbar-track:hover {
		background-color: rgba(150, 150, 150, 0.3);
		border-radius: 5px;
	}
	::-webkit-scrollbar-track:window-inactive {
		background-color: transparent;
	}
	::-webkit-scrollbar-thumb {
		// background-color: transparent;
		// width: 8px;
		background-color: rgba(204, 204, 204, 0.5);
		border-radius: 5px;
		transition: 0.4s;
		cursor: pointer;
	}
	::-webkit-scrollbar-thumb:hover {
		background-color: rgba(153, 153, 153, 0.8);
		cursor: pointer;
	}
	::-webkit-scrollbar-thumb:window-inactive {
		// background-color: yellow;
		background-color: transparent;
	}
}

body {
	padding: 0;
	margin: 0;
	height: 100%;
	overflow: hidden;
	font-weight: 400;
	font-family: var(--font-primary);
	font-size: var(--text-lg);
	letter-spacing: 0.4px;
}

:root {
	--font-primary: "Roboto";
	--font-secondary: sans-serif;
	--default-text-color: #1d2440;

	/* ---------- primary and secondary ---------- */
	--primary-main: #758fff;
	--primary-main-deep: #5d72cc;
	--primary-light: #abbfff;
	--primary-light-deep: #a1b4f2;
	--primary-dark: #3b62cb;
	--secondary-main: #f5f7f9;
	--secondary-main-deep: #e8edf2;
	--secondary-light: #ffffff;
	--secondary-dark: #c2c4c6;

	/* ---------- warning and dangerous ---------- */
	--warning: #ff7147;
	--warning-deep: #e14e21;

	/* ---------- shade ---------- */
	--shade05: rgba(0, 0, 0, 0.05);
	--shade10: rgba(0, 0, 0, 0.1);
	--shade20: rgba(0, 0, 0, 0.2);
	--shade40: rgba(0, 0, 0, 0.4);
	--shade60: rgba(0, 0, 0, 0.6);

	/* ---------- financial ---------- */
	--fs-equity: #463aa0;
	--fs-liabilities: #3da1d6;
	--fs-income: #76e5d9;
	--fs-expenses: #f5be40;
	--fs-assets: #da5d4d;

	/* ---------- project distribution ---------- */
	--normal-project: #0f1010;
	--locked-project: #fea621;
	--replica-project: #cccccc;
	--archived-project: #758fff;
	--unarchived-project: #ff2400;

	/* ---------- font-size ---------- */
	/* based on standard 16px */
	/* 20px / 18px / 16px / 14px / 12px / 11px / 9px / 8px */
	--text-xxxl: 1.25rem;
	--text-xxl: 1.125rem;
	--text-xl: 1rem;
	--text-lg: 0.875rem;
	--text-md: 0.75rem;
	--text-rg: 0.7rem;
	--text-sm: 0.6rem;
	--text-xs: 0.5rem;

	/* ---------- font-weight ---------- */
	--font-light: 300;
	--font-regular: 400;
	--font-medium: 500;
	--font-bold: 600;
	--font-black: 900;

	/* ---------- spacing ---------- */
	--spacing-xl: 2rem;
	--spacing-lg: 1.5rem;
	--spacing-md: 1rem;
	--spacing-rg: 0.5rem;
	--spacing-sm: 0.25rem;

	/* ---------- component standard ---------- */
	--base-unit: 16px;
	--standard-unit: 48px;
	// standard-unit = base-unit * 3

	/* ---------- shadow standard ---------- */
	--shadow-color-light: rgba(192, 203, 220, 0.6);
	--shadow-color-deep: rgba(192, 203, 220, 0.8);
	--shadow-rg-right-light: 2px 0px 10px 1px var(--shadow-color-light);
	--shadow-rg-right-deep: 2px 0px 10px 1px var(--shadow-color-deep);
}

h1 {
	font-size: var(--text-xxxl);
	margin: 0;
}
h2 {
	font-size: var(--text-xxl);
	margin: 0;
}
h3 {
	font-size: var(--text-xl);
	margin: 0;
}
h4 {
	font-size: var(--text-lg);
	margin: 0;
}
// ----- added part -----
.heading1 {
	font-size: 32px;
	font-weight: 600;
}
.heading2 {
	font-size: 20px;
}
.heading3 {
	font-size: 16px;
}
.heading4 {
	font-size: 14px;
}
.body1 {
	font-size: 16px;
}
.body2 {
	font-size: 14px;
}
// ----------------------
.sub-title {
	font-size: var(--text-md);
}
.black {
	font-weight: var(--font-black);
}
.bold {
	font-weight: var(--font-bold);
}
.medium {
	font-weight: var(--font-medium);
}
.light {
	font-weight: var(--font-light);
}
.small-text {
	font-size: var(--text-sm);
}
.regular-text {
	font-size: var(--text-rg);
}

a {
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
	text-decoration: none;
	color: inherit;
	cursor: pointer;
	font-size: inherit;
}
a:active,
a:hover {
	outline-width: 0;
}

input {
	color: inherit;
}

.FR {
	display: flex;
	flex-direction: row;
}
.FC {
	display: flex;
	flex-direction: column;
}
.AC {
	align-items: center;
}
.AL {
	align-items: flex-start;
}
.AR {
	align-items: flex-end;
}
.JC {
	justify-content: center;
}
.JL {
	justify-content: flex-start;
}
.JR {
	justify-content: flex-end;
}
.JSB {
	justify-content: space-between;
}
.JSA {
	justify-content: space-around;
}
